import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import img404 from "../images/404.jpg"
import Image from "react-bootstrap/Image"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="Page not found"/>
    <Container fluid className="px-5 py-5 about-container">
      <Row noGutters>
        <Col className="col-md-10 offset-md-1">
          <Container fluid>
            <Row noGutters>
              <h1 className="hero-title">
                NOT FOUND
                <hr className="hero-separator"/>
              </h1>
            </Row>
            <Row noGutters>
              <a className="mx-auto"
                 href='https://www.freepik.com/free-photos-vectors/design'
                 target='_blank' rel='noopener noreferrer'
                 aria-label="image source"
                 title='Design vector created by freepik - www.freepik.com'>
                <Image src={img404} alt="404 image" className="w-100 img-404"/>
              </a>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
